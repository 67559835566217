<template>
  <div ref="document">
    <div v-for="(chouhyouData, chouhyouIndex) in listChouhyou" v-bind:key="chouhyouIndex + chouhyouData.page" :aria-label="`${constData.chouhyouId}-${chouhyouData.page}`">
      <div>
        <TemplateNormal :id="constData.chouhyouId + chouhyouIndex" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/order_record/delivery_date_notification/normal.svg';
import { setPaperA4, splitMultiRowString, base64Decode } from '@/assets/js/common';
import { listV_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
export default {
  name: 'DeliveryDateNotification',
  /* コンポーネント */
  components: {
    TemplateNormal
  },
  /* パラメータ */
  props:['params'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 30,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '納期ご連絡票（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      // デコードしたparams
      decodedParams: null,
      //総ページ数
      pageCount: 0,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  async mounted() {
    // ローディングON
    this.$store.commit("setLoading", true);

    // paramsをデコード
    this.decodedParams = await this.decodeParams();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async decodeParams() {
      try {
        let decoded = await base64Decode(this.params);
        const decodedParams = JSON.parse(decoded);
        // console.log({decodedParams});
        return decodedParams;
      } catch (error) {
        //TODO エラー処理
      }
    },
    async fetchData(){
      // 指定された発注データを設定
      await this.setOrders();
    },
    /* 発注データ設定 */
    async setOrders(){
      try {
        let result = await API.graphql(graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI,{where_options: this.decodedParams.whereOptions}));
        let orderData = result.data.listV_HACCHU_DATA_WITH_MEISAI;

        // console.log({orderData});

        // 明細データを得意先名でGroupBy
        const dataByCustomer = orderData.reduce((groups, item) => {
          const group = (groups[item.CSS_TOKUISAKI_NAME] || []);
          group.push(item);
          groups[item.CSS_TOKUISAKI_NAME] = group;
          return groups;
        }, {}); 

        // console.log({dataByCustomer});

        if(orderData && orderData.length > 0){
          // 印刷レイアウト設定
          await setPaperA4();

          // this.listChouhyou = [];
          this.replacementsCommon = [];

          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(dataByCustomer);
          // 得意先ごとのデータに帳票番号と帳票内の項番をセット
          const dataByCustomerWithIndex = await this.setItemNumbers(dataByCustomer);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(dataByCustomerWithIndex);

          // const listChouhyou = this.listChouhyou;
          // console.log({listChouhyou});
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            await this.exportToPDF();
          }
        }

        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        // console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"], where_options: this.decodedParams.whereOptions}, this.$route.name, 'setOrders', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(dataByCustomer){
      // console.log('initListChouhyou');
      for (const [key, customerData] of Object.entries(dataByCustomer)) { // eslint-disable-line
        // console.log({customerData});
        // const len = customerData.length;
        // console.log({len});
        
        let productCnt = customerData.length;
        
        let productCntRemain = productCnt;
        let page = 1;
        while (productCntRemain > this.constData.cntNormal) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
          this.listChouhyou.push({page: page, replacements: []});
          productCntRemain -= this.constData.cntNormal;
          page++;
        }
        // 最終ページ
        this.listChouhyou.push({page: page, replacements: []});
        // 総ページ数を保持
        this.pageCount = page;
      }
      // const chohyoList = this.listChouhyou;
      // console.log({chohyoList});
    },
    /* 発注データに何番目の帳票のデータか、その帳票内の何番目のデータかを設定 */
    async setItemNumbers(dataByCustomer){
      // console.log('setItemNumbers');
      // console.log({dataByCustomer});
      let chouhyouNum = 0;
      let dataByCustomerWithIndex = [];
      // 得意先ごとにループ
      for (const [key, customerData] of Object.entries(dataByCustomer)) { // eslint-disable-line
        // console.log({customerData});
        // const len = customerData.length;
        // console.log({len});
        // 得意先のデータの明細ごとにループ
        for (let customerItemIndex = 0; customerItemIndex < customerData.length; customerItemIndex++) {
          if (customerData.length <= this.constData.cntNormal) {
            // 何番目の帳票か設定
            customerData[customerItemIndex].chouhyouIndex = chouhyouNum + Math.floor(customerItemIndex / this.constData.cntNormal);
            // 帳票内での項番を設定
            customerData[customerItemIndex].indexInChouhyou = customerItemIndex % this.constData.cntNormal;
          } else {
            // 何番目の帳票か設定
            customerData[customerItemIndex].chouhyouIndex = chouhyouNum + Math.floor(customerItemIndex / this.constData.cntNormal);
            // 帳票内での項番を設定
            customerData[customerItemIndex].indexInChouhyou = customerItemIndex % this.constData.cntNormal;
          }
        }
        chouhyouNum += Math.ceil(customerData.length / this.constData.cntNormal);
        // console.log({customerData});
        dataByCustomerWithIndex.push(customerData);
      }
      // console.log({dataByCustomerWithIndex});
      return dataByCustomerWithIndex;
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(dataByCustomerWithIndex){
      // console.log('createReplacementsPage');
      // console.log({dataByCustomerWithIndex});
      // 得意先ごとにループ
      for (const customerData of dataByCustomerWithIndex) {
        // 得意先データを帳票番号でGroupByして1帳票ごとのデータを取得
        const dataByChouhyou = customerData.reduce((groups, item) => {
          const group = (groups[item.chouhyouIndex] || []);
          group.push(item);
          groups[item.chouhyouIndex] = group;
          return groups;
        }, {});

        // console.log({dataByChouhyou});

        // 得意先のデータを1帳票分ごとにループ
        for (const [key, meisaiData] of Object.entries(dataByChouhyou)) {
          // let productsIndex = 0;
          // let whiteFlg = false;

          let chouhyouIndex = Number(key);
          
          // console.log({chouhyouIndex});
          // console.log({meisaiData})

          // SVGファイルの置換用文字列
          let replacements = this.listChouhyou[chouhyouIndex].replacements;
          // 帳票毎に設定可能な製品の件数
          let productCntByChouhyou = this.constData.cntNormal;

          /* ヘッダ・フッタ */
          // 摘要
          replacements.push({key: '%摘要%', value: this.decodedParams.description ?? '', textAnchor: 'start', textLength: 1430});
          // 回答日
          const now = new Date();
          const today = `${now.getFullYear()}年${now.getMonth()+1}月${now.getDate()}日`;
          replacements.push({key: '%回答日%', value: today, textAnchor: 'start', textLength: 231});
          // 発信者
          // 部門コード
          const user = this.$store.getters.user;
          const tantosha = user.TANTOSHA_NAME;
          this.replacementsCommon.push({key: '%発信者%', value: tantosha ?? '', textAnchor: 'start', textLength: 176});
          // 得意先名
          replacements.push({key: '%得意先名%', value: meisaiData[0].CSS_TOKUISAKI_NAME ?? '', textAnchor: 'middle', textLength: 656});
          // 得意先担当者名
          console.log(`tokuisakiInfo`, this.decodedParams.tokuisakiInfo);
          const client = this.decodedParams.tokuisakiInfo.find(
            (items) => items.CSS_TOKUISAKI_CD === meisaiData[0].CSS_TOKUISAKI_CD
          );
          console.log(`client`, client);
          replacements.push({key: '%担名%', value: client?.tokuisakiTantoshaName ?? '', textAnchor: 'end', textLength: 156});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 摘要
          replacements.push({key: '%摘要%', value: this.decodedParams.description ?? '', textAnchor: 'start', textLength: 1430});
          
          // 製品
          for (let j = 0; j < productCntByChouhyou; j++){
            // let meisai = meisaiData[j];
            // console.log({meisai});
            if(meisaiData[j]){
              // 品名・品番
              replacements.push({key: `%品番${j.toString()}%`, value: meisaiData[j].SHOHIN_NAME ? meisaiData[j].SHOHIN_NAME : '', textAnchor: 'start', textLength: 537});
              // 数量
              replacements.push({key: `%数量${j.toString()}%`, value: meisaiData[j].SURYO_SU ? `${meisaiData[j].SURYO_SU}` : '', textAnchor: 'end', textLength: 165});
              // 入荷日
              if(meisaiData[j].CHAKUBI_DATE) {
                const dateParts = meisaiData[j].CHAKUBI_DATE.split('-');
                replacements.push({key: `%入荷日${j.toString()}%`, value: `${dateParts[1].replace(/\b0+/, '')}/${dateParts[2].replace(/\b0+/, '')}`, textAnchor: 'end', textLength: 175});
              } else {
                replacements.push({key: `%入荷日${j.toString()}%`, value: '', textAnchor: 'end', textLength: 175});
              }
              // 備考
              replacements.push({key: `%備考${j.toString()}%`, value: meisaiData[j].GENBA_NAME ? meisaiData[j].GENBA_NAME : '', textAnchor: 'start', textLength: 486});
            } else {
              // 数量
              replacements.push({key: `%数量${j.toString()}%`, value: '', textAnchor: 'end', textLength: 165});
              // 入荷日
              replacements.push({key: `%入荷日${j.toString()}%`, value: '', textAnchor: 'end', textLength: 175});
              // 備考
              replacements.push({key: `%備考${j.toString()}%`, value: '', textAnchor: 'start', textLength: 486});
              // 品名・品番
              replacements.push({key: `%品番${j.toString()}%`, value: '', textAnchor: 'start', textLength: 420});
            }
          }
          // ページ番号
          replacements.push({key: '%ページ番号%', value: `${this.listChouhyou[chouhyouIndex].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});
          // console.log({chouhyouIndex});
          // console.log({replacements});
          // this.listChouhyou[chouhyouIndex].replacements = replacements;
          // const list = this.listChouhyou[chouhyouIndex];
          // console.log({list});
        }
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const bumon = result.data.getM_SHOKISETTEI;
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門名称
      replacements.push({key: '%部門名%', value: bumon.BUMON_NAME ?? '', textAnchor: 'start', textLength: 245});
      // TEL
      replacements.push({key: '%TEL%', value: bumon.DENWA_NO ?? '', textAnchor: 'start', textLength: 221});
      // FAX
      replacements.push({key: '%FAX%', value: bumon.FAX_NO ?? '', textAnchor: 'start', textLength: 221});
    },
    /* 置換文字列に品番を設定 */
    async setProductNum(replacements, rowNum, name) {
      let arySummary = splitMultiRowString(name, 50);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%品番${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 537});
        } else {
          replacements.push({key: `%品番${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 537});
        }
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 50);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 486});
        } else {
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 486});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        // console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + i);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '納期一覧表.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>